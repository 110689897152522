import { colors } from "../colors";

// Availability

export const cl_synop_in_oscar = ["==", ["get", "in_oscar"], true];
const cl_synop_not_in_oscar = ["==", ["get", "in_oscar"], false];

const cl_synop_silent = [
  "==",
  ["/", ["get", "nr_received"], ["get", "nr_expected"]],
  0
];

const cl_synop_big_issues = partBaseline => {
  return [
    "all",
    [">", ["/", ["get", "nr_received"], partBaseline], 0],
    ["<", ["/", ["get", "nr_received"], partBaseline], 0.3]
  ];
};

const cl_gbon_synop_big_issues = partBaseline => {
  return [
    "all",
    [">", ["/", ["get", "nr_received"], partBaseline], 0],
    ["<", ["/", ["get", "nr_received"], partBaseline], 0.3]
  ];
};

const cl_synop_small_issues = partBaseline => {
  return [
    "all",
    [">=", ["/", ["get", "nr_received"], partBaseline], 0.3],
    ["<", ["/", ["get", "nr_received"], partBaseline], 0.8]
  ];
};

const cl_gbon_synop_small_issues = partBaseline => {
  return [
    "all",
    [">=", ["/", ["get", "nr_received"], partBaseline], 0.3],
    ["<", ["/", ["get", "nr_received"], partBaseline], 0.8]
  ];
};

const cl_synop_normal = partBaseline => {
  return [
    "all",
    [">=", ["/", ["get", "nr_received"], partBaseline], 0.8],
    ["<=", ["/", ["get", "nr_received"], partBaseline], 1]
  ];
};

const cl_gbon_synop_normal = partBaseline => {
  return ["all", [">=", ["/", ["get", "nr_received"], partBaseline], 0.8]];
};

const cl_synop_moreThan100p = partBaseline => {
  return [
    "all",
    [">", partBaseline, 0],
    [">", ["/", ["get", "nr_received"], partBaseline], 1]
  ];
};

const cl_synop_expectedZero = partBaseline => {
  return ["all", ["==", partBaseline, 0], [">", ["get", "nr_received"], 0]];
};

const cl_gbon_synop_expectedZero = partBaseline => {
  return ["all", ["==", partBaseline, 0], [">", ["get", "nr_received"], 0]];
};

export const getStyleSynopAvailability = (baseline, period_type) => {
  let valuePeriodType = 6; // for six_hour
  if (period_type === "daily") {
    valuePeriodType = 24;
  } else if (period_type === "monthly") {
    // monthly
    valuePeriodType = ["get", "nr_full_days"];
    valuePeriodType = ["*", valuePeriodType, 24];
  }
  const partBaseline =
    baseline === "OSCAR" ? ["get", "nr_expected"] : valuePeriodType;

  return [
    "case",
    cl_synop_not_in_oscar,
    colors.map.synop.availability[6],
    cl_synop_in_oscar,
    [
      "case",
      cl_synop_silent,
      colors.map.synop.availability[4],
      cl_synop_big_issues(partBaseline),
      colors.map.synop.availability[3],
      cl_synop_small_issues(partBaseline),
      colors.map.synop.availability[2],
      cl_synop_normal(partBaseline),
      colors.map.synop.availability[1],
      cl_synop_moreThan100p(partBaseline),
      colors.map.synop.availability[0],
      cl_synop_expectedZero(partBaseline),
      colors.map.synop.availability[5],
      /* other - never used here but needed */ colors.map.classNeeded
    ],
    // below is never used here but needed
    colors.map.classNeeded
  ];
};

export const getStyleGbonSynopAvailability = (baseline, period_type) => {
  let valuePeriodType = 6;
  if (period_type === "daily") {
    valuePeriodType = 24;
  } else {
    // monthly
    valuePeriodType = ["get", "nr_expected"];
  }
  const partBaseline = valuePeriodType;

  return [
    "case",
    cl_synop_not_in_oscar,
    colors.map.synop.availability[6],
    cl_synop_in_oscar,
    [
      "case",
      cl_synop_silent,
      colors.map.synop.availability[4],
      cl_gbon_synop_big_issues(partBaseline),
      colors.map.synop.availability[3],
      cl_gbon_synop_small_issues(partBaseline),
      colors.map.synop.availability[2],
      cl_gbon_synop_normal(partBaseline),
      colors.map.synop.availability[1],
      cl_gbon_synop_expectedZero(partBaseline),
      colors.map.synop.availability[5],
      /* other - never used here but needed */ colors.map.classNeeded
    ],
    // below is never used here but needed
    colors.map.classNeeded
  ];
};

// Quality

const cl_synop_le_0p02 = ["<=", ["get", "avg_bg_dep"], 0.02];

const cl_synop_le_0p5 = ["<=", ["get", "avg_bg_dep"], 0.5];

const cl_synop_le_30 = ["<=", ["get", "avg_bg_dep"], 30];

const cl_synop_gt_0p02_le_0p05 = [
  "all",
  [">", ["get", "avg_bg_dep"], 0.02],
  ["<=", ["get", "avg_bg_dep"], 0.05]
];

const cl_synop_gt_0p05_le_0p1 = [
  "all",
  [">", ["get", "avg_bg_dep"], 0.05],
  ["<=", ["get", "avg_bg_dep"], 0.1]
];

const cl_synop_gt_0p1_le_0p15 = [
  "all",
  [">", ["get", "avg_bg_dep"], 0.1],
  ["<=", ["get", "avg_bg_dep"], 0.15]
];

const cl_synop_gt_0p15_le_0p3 = [
  "all",
  [">", ["get", "avg_bg_dep"], 0.15],
  ["<=", ["get", "avg_bg_dep"], 0.3]
];

const cl_synop_gt_0p5_le_1 = [
  "all",
  [">", ["get", "avg_bg_dep"], 0.5],
  ["<=", ["get", "avg_bg_dep"], 1]
];

const cl_synop_gt_0p5_le_2 = [
  "all",
  [">", ["get", "avg_bg_dep"], 0.5],
  ["<=", ["get", "avg_bg_dep"], 2]
];

const cl_synop_gt_1_le_2 = [
  "all",
  [">", ["get", "avg_bg_dep"], 1],
  ["<=", ["get", "avg_bg_dep"], 2]
];

const cl_synop_gt_1_le_5 = [
  "all",
  [">", ["get", "avg_bg_dep"], 1],
  ["<=", ["get", "avg_bg_dep"], 5]
];
const cl_synop_gt_2_le_3 = [
  "all",
  [">", ["get", "avg_bg_dep"], 2],
  ["<=", ["get", "avg_bg_dep"], 3]
];

const cl_synop_gt_2_le_5 = [
  "all",
  [">", ["get", "avg_bg_dep"], 2],
  ["<=", ["get", "avg_bg_dep"], 5]
];

const cl_synop_gt_3_le_5 = [
  "all",
  [">", ["get", "avg_bg_dep"], 3],
  ["<=", ["get", "avg_bg_dep"], 5]
];

const cl_synop_gt_5_le_10 = [
  "all",
  [">", ["get", "avg_bg_dep"], 5],
  ["<=", ["get", "avg_bg_dep"], 10]
];

const cl_synop_gt_5_le_15 = [
  "all",
  [">", ["get", "avg_bg_dep"], 5],
  ["<=", ["get", "avg_bg_dep"], 15]
];

const cl_synop_gt_30_le_40 = [
  "all",
  [">", ["get", "avg_bg_dep"], 30],
  ["<=", ["get", "avg_bg_dep"], 40]
];
const cl_synop_gt_40_le_100 = [
  "all",
  [">", ["get", "avg_bg_dep"], 40],
  ["<=", ["get", "avg_bg_dep"], 100]
];

const cl_synop_gt_0p3 = [">", ["get", "avg_bg_dep"], 0.3];

const cl_synop_gt_10 = [">", ["get", "avg_bg_dep"], 10];

const cl_synop_gt_15 = [">", ["get", "avg_bg_dep"], 15];

const cl_synop_gt_100 = [">", ["get", "avg_bg_dep"], 100];

export const getStyleSynopQuality = varid => {
  let style;
  if (varid === "110") {
    style = [
      "case",
      cl_synop_le_0p5,
      colors.map.synop.quality[0],
      cl_synop_gt_0p5_le_1,
      colors.map.synop.quality[1],
      cl_synop_gt_1_le_5,
      colors.map.synop.quality[3],
      cl_synop_gt_5_le_10,
      colors.map.synop.quality[4],
      cl_synop_gt_10,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else if (varid === "39") {
    style = [
      "case",
      cl_synop_le_0p5,
      colors.map.synop.quality[0],
      cl_synop_gt_0p5_le_1,
      colors.map.synop.quality[1],
      cl_synop_gt_1_le_2,
      colors.map.synop.quality[2],
      cl_synop_gt_2_le_5,
      colors.map.synop.quality[3],
      cl_synop_gt_5_le_10,
      colors.map.synop.quality[4],
      cl_synop_gt_10,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else if (varid === "41" || varid === "42") {
    style = [
      "case",
      cl_synop_le_0p5,
      colors.map.synop.quality[0],
      cl_synop_gt_0p5_le_2,
      colors.map.synop.quality[1],
      cl_synop_gt_2_le_3,
      colors.map.synop.quality[2],
      cl_synop_gt_3_le_5,
      colors.map.synop.quality[3],
      cl_synop_gt_5_le_15,
      colors.map.synop.quality[4],
      cl_synop_gt_15,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else if (varid === "58") {
    style = [
      "case",
      cl_synop_le_0p02,
      colors.map.synop.quality[0],
      cl_synop_gt_0p02_le_0p05,
      colors.map.synop.quality[1],
      cl_synop_gt_0p05_le_0p1,
      colors.map.synop.quality[2],
      cl_synop_gt_0p1_le_0p15,
      colors.map.synop.quality[3],
      cl_synop_gt_0p15_le_0p3,
      colors.map.synop.quality[4],
      cl_synop_gt_0p3,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else {
    // Geopotential (varid = 1)
    style = [
      "case",
      cl_synop_le_30,
      colors.map.synop.quality[0],
      cl_synop_gt_30_le_40,
      colors.map.synop.quality[3],
      cl_synop_gt_40_le_100,
      colors.map.synop.quality[4],
      cl_synop_gt_100,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  }
  return style;
};

const cl_synop_monthly_le_0p02 = ["<=", ["get", "rms"], 0.02];

const cl_synop_monthly_le_0p5 = ["<=", ["get", "rms"], 0.5];

const cl_synop_monthly_le_30 = ["<=", ["get", "rms"], 30];

const cl_synop_monthly_gt_0p02_le_0p05 = [
  "all",
  [">", ["get", "rms"], 0.02],
  ["<=", ["get", "rms"], 0.05]
];

const cl_synop_monthly_gt_0p05_le_0p1 = [
  "all",
  [">", ["get", "rms"], 0.05],
  ["<=", ["get", "rms"], 0.1]
];

const cl_synop_monthly_gt_0p1_le_0p15 = [
  "all",
  [">", ["get", "rms"], 0.1],
  ["<=", ["get", "rms"], 0.15]
];

const cl_synop_monthly_gt_0p15_le_0p3 = [
  "all",
  [">", ["get", "rms"], 0.15],
  ["<=", ["get", "rms"], 0.3]
];

const cl_synop_monthly_gt_0p5_le_1 = [
  "all",
  [">", ["get", "rms"], 0.5],
  ["<=", ["get", "rms"], 1]
];

const cl_synop_monthly_gt_0p5_le_2 = [
  "all",
  [">", ["get", "rms"], 0.5],
  ["<=", ["get", "rms"], 2]
];

const cl_synop_monthly_gt_1_le_2 = [
  "all",
  [">", ["get", "rms"], 1],
  ["<=", ["get", "rms"], 2]
];

const cl_synop_monthly_gt_1_le_5 = [
  "all",
  [">", ["get", "rms"], 1],
  ["<=", ["get", "rms"], 5]
];
const cl_synop_monthly_gt_2_le_3 = [
  "all",
  [">", ["get", "rms"], 2],
  ["<=", ["get", "rms"], 3]
];

const cl_synop_monthly_gt_2_le_5 = [
  "all",
  [">", ["get", "rms"], 2],
  ["<=", ["get", "rms"], 5]
];

const cl_synop_monthly_gt_3_le_5 = [
  "all",
  [">", ["get", "rms"], 3],
  ["<=", ["get", "rms"], 5]
];

const cl_synop_monthly_gt_5_le_10 = [
  "all",
  [">", ["get", "rms"], 5],
  ["<=", ["get", "rms"], 10]
];

const cl_synop_monthly_gt_5_le_15 = [
  "all",
  [">", ["get", "rms"], 5],
  ["<=", ["get", "rms"], 15]
];

const cl_synop_monthly_gt_30_le_40 = [
  "all",
  [">", ["get", "rms"], 30],
  ["<=", ["get", "rms"], 40]
];
const cl_synop_monthly_gt_40_le_100 = [
  "all",
  [">", ["get", "rms"], 40],
  ["<=", ["get", "rms"], 100]
];

const cl_synop_monthly_gt_0p3 = [">", ["get", "rms"], 0.3];

const cl_synop_monthly_gt_10 = [">", ["get", "rms"], 10];

const cl_synop_monthly_gt_15 = [">", ["get", "rms"], 15];

const cl_synop_monthly_gt_100 = [">", ["get", "rms"], 100];
export const getStyleSynopMonthlyQuality = varid => {
  let style;
  if (varid === "110") {
    style = [
      "case",
      cl_synop_monthly_le_0p5,
      colors.map.synop.quality[0],
      cl_synop_monthly_gt_0p5_le_1,
      colors.map.synop.quality[1],
      cl_synop_monthly_gt_1_le_5,
      colors.map.synop.quality[3],
      cl_synop_monthly_gt_5_le_10,
      colors.map.synop.quality[4],
      cl_synop_monthly_gt_10,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else if (varid === "39") {
    style = [
      "case",
      cl_synop_monthly_le_0p5,
      colors.map.synop.quality[0],
      cl_synop_monthly_gt_0p5_le_1,
      colors.map.synop.quality[1],
      cl_synop_monthly_gt_1_le_2,
      colors.map.synop.quality[2],
      cl_synop_monthly_gt_2_le_5,
      colors.map.synop.quality[3],
      cl_synop_monthly_gt_5_le_10,
      colors.map.synop.quality[4],
      cl_synop_monthly_gt_10,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else if (varid === "41" || varid === "42") {
    style = [
      "case",
      cl_synop_monthly_le_0p5,
      colors.map.synop.quality[0],
      cl_synop_monthly_gt_0p5_le_2,
      colors.map.synop.quality[1],
      cl_synop_monthly_gt_2_le_3,
      colors.map.synop.quality[2],
      cl_synop_monthly_gt_3_le_5,
      colors.map.synop.quality[3],
      cl_synop_monthly_gt_5_le_15,
      colors.map.synop.quality[4],
      cl_synop_monthly_gt_15,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else if (varid === "58") {
    style = [
      "case",
      cl_synop_monthly_le_0p02,
      colors.map.synop.quality[0],
      cl_synop_monthly_gt_0p02_le_0p05,
      colors.map.synop.quality[1],
      cl_synop_monthly_gt_0p05_le_0p1,
      colors.map.synop.quality[2],
      cl_synop_monthly_gt_0p1_le_0p15,
      colors.map.synop.quality[3],
      cl_synop_monthly_gt_0p15_le_0p3,
      colors.map.synop.quality[4],
      cl_synop_monthly_gt_0p3,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else {
    // Geopotential (varid = 1)
    style = [
      "case",
      cl_synop_monthly_le_30,
      colors.map.synop.quality[0],
      cl_synop_monthly_gt_30_le_40,
      colors.map.synop.quality[3],
      cl_synop_monthly_gt_40_le_100,
      colors.map.synop.quality[4],
      cl_synop_monthly_gt_100,
      colors.map.synop.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  }
  return style;
};
