import { RFC_2822 } from "moment";
import { allCenters } from "./general";

export const formatProperty = (property, multiplier, abs = true) => {
  // In the pop-up we display the absolute values (like on the map)
  // Add optional param abs in case we don't want to calculate the absolute value
  // for case of monthly quality synop
  let ret = "";
  if (property === null) {
    ret = "No data";
  } else if (abs) {
    ret = Math.round(Math.abs(property) * multiplier * 1000) / 1000;
  } else {
    ret = Math.round(property * multiplier * 1000) / 1000;
  }
  return ret;
};

const templateContentSimple = [
  { name: "Name", value: "" },
  { name: "WIGOS-ID", value: null },
  { name: "NWP-ID", value: null },
  { name: "#Received", value: null },
  { name: "#Expected", value: null },
  { name: "Value", value: null },
  { name: "Default schedule", value: null },
  { name: "avg(O-B)", value: null },
  { name: "STD(O-B)", value: null },
  { name: "RMSe", value: null }
];

const templateContentCombined = [
  { name: "Name", value: "" },
  { name: "WIGOS-ID", value: null },
  { name: "NWP-ID", value: null },
  { name: "#Expected", value: null },
  { name: "Default schedule", value: null }
];

export const mergeDataSources = (
  selectedReport,
  dataFeature,
  dataAPI,
  multiplier,
  selectedPeriodType
) => {
  // deep copy
  const arr = templateContentSimple.map(a => ({ ...a }));
  let oscar_id = null;
  arr[0].value = dataAPI.name;
  if (dataAPI.name === "Unknown") {
    arr[2].value = dataAPI.wigosid;
  } else {
    arr[1].value = dataAPI.wigosid;
    oscar_id = arr[1].value;
  }
  if (selectedReport === "availability") {
    arr[3].value = dataFeature.nr_received;
    arr[4].value = dataFeature.nr_expected;
  } else if (selectedPeriodType === "monthly") {
    arr[7].value = formatProperty(dataFeature.avg_bg_dep, multiplier, false);
    arr[8].value = formatProperty(dataFeature.std_bg_dep, multiplier);
    arr[9].value = formatProperty(dataFeature.rms, multiplier);
  } else {
    arr[5].value = formatProperty(dataFeature.avg_bg_dep, multiplier);
  }

  const arrFiltered = arr.filter(e => e.value !== null);

  return { data: arrFiltered, oscar_id };
};

export const formatDataSimpleFromAPI = (props, dataAPI) => {
  // deep copy
  const arr = templateContentSimple.map(a => ({ ...a }));

  let oscar_id = null;
  arr[0].value = dataAPI[0].station;
  if (arr[0].value === "Unknown") {
    arr[2].value = dataAPI[0].wigosid;
  } else {
    arr[1].value = dataAPI[0].wigosid;
    oscar_id = arr[1].value;
  }
  arr[3].value = dataAPI[0].nr_received;
  // if nr_expected is null, set it to 0
  if (
    props.baselinetxt === "Hourly" &&
    props.selectedPeriodType === "six_hour"
  ) {
    arr[4].value = 6;
  }
  if (props.baselinetxt === "Hourly" && props.selectedPeriodType === "daily") {
    arr[4].value = 24;
  } else if (
    props.baselinetxt === "Hourly" &&
    props.selectedPeriodType === "monthly"
  ) {
    arr[4].value = getNrfulldays(dataAPI) * 24;
  } else {
    arr[4].value = dataAPI[0].nr_expected === null ? 0 : dataAPI[0].nr_expected;
  }

  if (dataAPI.length === 2) {
    // This means get the max between geopotential and surface pressure
    arr[3].value = Math.max(dataAPI[0].nr_received, dataAPI[1].nr_received);
    arr[4].value =
      dataAPI[0].nr_expected === null
        ? 0
        : Math.max((dataAPI[0].nr_expected, dataAPI[1].nr_expected));
  }
  // default schedule
  arr[6].value = dataAPI[0].default_schedule ? true : null;

  const arrFiltered = arr.filter(e => e.value !== null);
  return { data: arrFiltered, oscar_id };
};

export const getAllIndexes = (arr, val) => {
  var indexes = [];
  var i = 0;
  for (i = 0; i < arr.length; i++) {
    if (arr[i] === val) {
      indexes.push(i);
    }
  }
  return indexes;
};

export const formatDataCombinedFromAPI = (
  props,
  dataAPI,
  selectedReport,
  in_oscar,
  multiplier
) => {
  let fieldByCenter = "";
  // deep copy
  const arr = templateContentCombined.map(a => ({ ...a }));
  const availCenters = dataAPI.map(d => d.center);
  arr[0].value = dataAPI[0].station;
  let oscar_id = null;
  if (in_oscar) {
    arr[1].value = dataAPI[0].wigosid;
    oscar_id = arr[1].value;
  } else {
    arr[2].value = dataAPI[0].wigosid;
  }
  if (selectedReport === "availability") {
    fieldByCenter = "nr_received";
    if (in_oscar) {
      // if nr_expected is null, set it to 0

      if (
        props.baselinetxt === "Hourly" &&
        props.selectedPeriodType === "daily"
      ) {
        arr[3].value = 24;
      } else if (
        props.baselinetxt === "Hourly" &&
        props.selectedPeriodType === "six_hour"
      ) {
        arr[3].value = 6;
      } else if (
        props.baselinetxt === "Hourly" &&
        props.selectedPeriodType === "monthly"
      ) {
        arr[3].value = getNrfulldays(dataAPI) * 24;
      } else {
        arr[3].value =
          dataAPI[0].nr_expected === null ? 0 : getNrexpected(dataAPI);
      }
    }
  } else {
    fieldByCenter = "avg_bg_dep";
    if (dataAPI[0].station === "Unknown") {
      arr[2].value = dataAPI[0].wigosid;
    }
  }

  if (dataAPI[0].default_schedule) {
    arr[4].value = true;
  }
  allCenters.forEach((center, j) => {
    let geopotential = "";
    let value;
    let nr_expected = 0;
    let fieldNrExpected = "nr_expected";
    let fieldNrReceived = "nr_received";
    let fieldNrGrossError = "nr_gross_error";
    let fieldRms = "rms";
    let fieldAvgBgDep = "avg_bg_dep";
    let fieldStdBgDep = "std_bg_dep";
    if (availCenters.includes(center)) {
      const indexCenter = availCenters.indexOf(center);
      if (selectedReport === "availability") {
        // We may experience duplicate in case of station that measure both goepotential and surface pressure
        let indexes = getAllIndexes(availCenters, center);
        if (indexes.length === 2) {
          // We expect only two values
          let vars = { 1: "g", 110: "s" };
          let r1 = dataAPI[indexes[0]][fieldByCenter];
          let r2 = dataAPI[indexes[1]][fieldByCenter];
          let v1 = dataAPI[indexes[0]].var_id;
          let v2 = dataAPI[indexes[1]].var_id;
          value = Math.max(r1, r2);
          nr_expected = Math.max(
            dataAPI[indexes[0]][fieldNrExpected],
            dataAPI[indexes[1]][fieldNrExpected]
          );
          // geopotential =
          //   "* " + vars[v1] + ":" + r1 + " | " + vars[v2] + ":" + r2; // please un comment if you want to see the number of observation received for geopotential and surface in the popup togeather
        } else {
          value = dataAPI[indexCenter][fieldByCenter];
          // nr_expected = dataAPI[indexCenter][fieldNrExpected];
          nr_expected = getNrexpected(dataAPI);
        }
      } else if (selectedReport === "availability") {
        value = dataAPI[indexCenter][fieldByCenter];
        nr_expected = dataAPI[indexCenter][fieldNrExpected];
      } else {
        value = formatProperty(dataAPI[indexCenter][fieldByCenter], multiplier);
        fieldNrGrossError = dataAPI[indexCenter][fieldNrGrossError];
        fieldRms = dataAPI[indexCenter][fieldRms];
        fieldAvgBgDep = dataAPI[indexCenter][fieldAvgBgDep];
        fieldStdBgDep = dataAPI[indexCenter][fieldStdBgDep];
        fieldNrReceived = dataAPI[indexCenter][fieldNrReceived];
      }
    } else {
      value = "No data";
    }
    arr.splice(j + 3, 0, {
      name: `#${center}`,
      value,
      nr_expected: `${nr_expected}`,
      nr_received: `${fieldNrReceived}`,
      nr_gross_error: `${fieldNrGrossError}`,
      rms: `${fieldRms}`,
      avg_bg_dep: `${fieldAvgBgDep}`,
      std_bg_dep: `${fieldStdBgDep}`,
      geopotential: `${geopotential}`
    });
  });
  const arrFiltered = arr.filter(e => e.value !== null);
  return { data: arrFiltered, oscar_id };
};

export const getNrexpected = dataAPI => {
  // Get the maximum nr expected from all centers when it is combined
  var nr_expected = 0;
  for (let i = 0; i < dataAPI.length; i++) {
    if (dataAPI[i].nr_expected > nr_expected) {
      nr_expected = dataAPI[i].nr_expected;
    }
  }
  return nr_expected;
};

export const getNrfulldays = dataAPI => {
  // Get the maximum nr full days from all centers when it is combined
  var nr_full_days = 0;
  for (let i = 0; i < dataAPI.length; i++) {
    if (dataAPI[i].nr_full_days > nr_full_days) {
      nr_full_days = dataAPI[i].nr_full_days;
    }
  }
  return nr_full_days;
};

export const pickProperties = (obj, props) => {
  // Source: https://gomakethings.com/how-to-create-a-new-object-with-only-a-subject-of-properties-using-vanilla-js/
  // Make sure object and properties are provided
  if (!obj || !props) {
    return;
  }

  // Create new object
  var picked = {};

  // Loop through props and push to new object
  props.forEach(function(prop) {
    picked[prop] = obj[prop];
  });

  // Return new object
  return picked;
};
