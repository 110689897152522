import { colors } from "../colors";

// Availability

// // Six-hour
export const cl_temp_in_oscar = ["==", ["get", "in_oscar"], true];
const cl_temp_not_in_oscar = ["==", ["get", "in_oscar"], false];

const cl_temp_silent = [
  "==",
  ["/", ["get", "nr_received"], ["get", "nr_expected"]],
  0
];

// This case has been added for monthly as there was station has zero received complete observations
// and there is no expected
const cl_temp_no_expect_no_received = [
  "==",
  ["+", ["get", "nr_received"], ["get", "nr_expected"]],
  0
];
const cl_temp_avail_sixhour_all = ["==", ["get", "availability"], 3];
const cl_temp_avail_sixhour_missing_var = ["==", ["get", "availability"], 2];
const cl_temp_avail_sixhour_missing_level = ["==", ["get", "availability"], 1];
const cl_temp_avail_sixhour_missing_stra_humidity = [
  "==",
  ["get", "availability"],
  4
];

export const styleTempAvailabilitySixHour = [
  "case",
  cl_temp_not_in_oscar,
  colors.map.temp.availability.sixhour[4],
  cl_temp_in_oscar,
  [
    "case",
    cl_temp_silent,
    colors.map.temp.availability.sixhour[3],
    cl_temp_avail_sixhour_all,
    colors.map.temp.availability.sixhour[0],
    cl_temp_avail_sixhour_missing_var,
    colors.map.temp.availability.sixhour[1],
    cl_temp_avail_sixhour_missing_stra_humidity,
    colors.map.temp.availability.sixhour[2],
    cl_temp_avail_sixhour_missing_level,
    colors.map.temp.availability.sixhour[2],

    /* other - never used here but needed */
    colors.map.classNeeded
  ],
  // below is never used here but needed
  colors.map.classNeeded
];

// // Daily
const cl_temp_avail_daily_more_100p = partBaseline => {
  return [
    "all",
    [">", ["/", ["get", "nr_received"], partBaseline], 1],
    ["==", ["get", "availability"], 3]
  ];
};

const cl_temp_avail_daily_ok = partBaseline => {
  return [
    "all",
    ["==", ["/", ["get", "nr_received"], partBaseline], 1],
    ["==", ["get", "availability"], 3]
  ];
};
const cl_temp_gbon_avail_daily_ok = partBaseline => {
  return ["all", [">=", ["/", ["get", "nr_received"], partBaseline], 0.8]];
};
const cl_temp_avail_daily_comp_issue = partBaseline => {
  return [
    "all",
    [">=", ["/", ["get", "nr_received"], partBaseline], 1],
    ["<", ["get", "availability"], 3]
  ];
};
const cl_temp_avail_daily_comp_issue_stra = partBaseline => {
  return [
    "all",
    [">=", ["/", ["get", "nr_received"], partBaseline], 1],
    ["==", ["get", "availability"], 4]
  ];
};
const cl_temp_gbon_avail_daily_comp_issue = partBaseline => {
  return [
    "all",
    [">=", ["/", ["get", "nr_received"], partBaseline], 0.3],
    ["<", ["/", ["get", "nr_received"], partBaseline], 0.8]
  ];
};
const cl_temp_gbon_avail_daily_big_issue = partBaseline => {
  return [
    "all",
    [">", ["/", ["get", "nr_received"], partBaseline], 0],
    ["<", ["/", ["get", "nr_received"], partBaseline], 0.3]
  ];
};

const cl_temp_avail_daily_avail_issue = partBaseline => {
  return ["<", ["/", ["get", "nr_received"], partBaseline], 1];
};

export const getStyleTempAvailabilitySixhour = baseline => {
  const partBaseline = baseline === "OSCAR" ? ["get", "nr_expected"] : 0;
  return [
    "case",
    cl_temp_not_in_oscar,
    colors.map.temp.availability.sixhour[4],
    cl_temp_in_oscar,
    [
      "case",
      cl_temp_silent,
      colors.map.temp.availability.sixhour[3],
      cl_temp_avail_sixhour_all,
      colors.map.temp.availability.sixhour[0],
      cl_temp_avail_sixhour_missing_var,
      colors.map.temp.availability.sixhour[1],
      cl_temp_avail_sixhour_missing_stra_humidity,
      colors.map.temp.availability.daily[2],
      cl_temp_avail_sixhour_missing_level,
      colors.map.temp.availability.sixhour[2],

      /* other - never used here but needed */
      colors.map.classNeeded
    ],
    // below is never used here but needed
    colors.map.classNeeded
  ];
};
export const getStyleTempAvailabilityDaily = baseline => {
  const partBaseline = baseline === "OSCAR" ? ["get", "nr_expected"] : 2;

  return [
    "case",
    cl_temp_not_in_oscar,
    colors.map.temp.availability.daily[5],
    cl_temp_in_oscar,
    [
      "case",
      cl_temp_silent,
      colors.map.temp.availability.daily[4],
      cl_temp_avail_daily_more_100p(partBaseline),
      colors.map.temp.availability.daily[0],
      cl_temp_avail_daily_ok(partBaseline),
      colors.map.temp.availability.daily[1],
      cl_temp_avail_daily_comp_issue_stra(partBaseline),
      colors.map.temp.availability.daily[2],
      cl_temp_avail_daily_comp_issue(partBaseline),
      colors.map.temp.availability.daily[2],

      cl_temp_avail_daily_avail_issue(partBaseline),
      colors.map.temp.availability.daily[3],

      /* other - never used here but needed */
      colors.map.classNeeded
    ],
    // below is never used here but needed
    colors.map.classNeeded
  ];
};

export const getStyleGbonTempAvailabilityDaily = baseline => {
  const partBaseline = 2;

  return [
    "case",
    cl_temp_not_in_oscar,
    colors.map.temp.availability.daily[5],
    cl_temp_in_oscar,
    [
      "case",
      cl_temp_silent,
      colors.map.temp.availability.daily[4],
      cl_temp_gbon_avail_daily_ok(partBaseline),
      colors.map.temp.availability.daily[1],
      cl_temp_gbon_avail_daily_comp_issue(partBaseline),
      colors.map.temp.availability.daily[2],
      cl_temp_gbon_avail_daily_big_issue(partBaseline),
      colors.map.temp.availability.daily[3],

      /* other - never used here but needed */
      colors.map.classNeeded
    ],
    // below is never used here but needed
    colors.map.classNeeded
  ];
};

export const getStyleGbonTempAvailabilityMonthly = baseline => {
  const partBaseline = ["get", "nr_expected"];

  return [
    "case",
    cl_temp_not_in_oscar,
    colors.map.temp.availability.daily[5],
    cl_temp_in_oscar,
    [
      "case",
      cl_temp_silent,
      colors.map.temp.availability.daily[4],
      cl_temp_gbon_avail_daily_ok(partBaseline),
      colors.map.temp.availability.daily[1],
      cl_temp_gbon_avail_daily_comp_issue(partBaseline),
      colors.map.temp.availability.daily[2],
      cl_temp_gbon_avail_daily_big_issue(partBaseline),
      colors.map.temp.availability.daily[3],

      /* other - never used here but needed */
      colors.map.classNeeded
    ],
    // below is never used here but needed
    colors.map.classNeeded
  ];
};

export const getStyleTempAvailabilityMonthly = baseline => {
  let valuePeriodType = 2;

  valuePeriodType = ["get", "nr_full_days"];
  valuePeriodType = ["*", valuePeriodType, 2];

  const partBaseline =
    baseline === "OSCAR" ? ["get", "nr_expected"] : valuePeriodType;
  return [
    "case",
    cl_temp_not_in_oscar,
    colors.map.temp.availability.daily[5],
    cl_temp_in_oscar,
    [
      "case",
      cl_temp_silent,
      colors.map.temp.availability.daily[4],
      cl_temp_no_expect_no_received,
      colors.map.temp.availability.daily[4],
      cl_temp_avail_monthly_more_100p(partBaseline),
      colors.map.temp.availability.daily[0],
      cl_temp_avail_monthly_more_100p_but_not_complete(partBaseline),
      colors.map.temp.availability.daily[0],
      cl_temp_avail_monthly_comp_issue(partBaseline),
      colors.map.temp.availability.daily[2],
      cl_temp_avail_monthly_avail_issue(partBaseline),
      colors.map.temp.availability.daily[3],
      cl_temp_avail_monthly_ok(partBaseline),
      colors.map.temp.availability.daily[1],
      // other - never used here but needed
      colors.map.classNeeded
    ],
    // below is never used here but needed
    colors.map.classNeeded
  ];
};
// Monthly
const cl_temp_avail_monthly_more_100p = partBaseline => {
  return [
    "all",
    [">", ["/", ["get", "nr_received"], partBaseline], 1],
    ["==", ["get", "availability"], 3]
  ];
};

// Monthly
const cl_temp_avail_monthly_more_100p_but_not_complete = partBaseline => {
  return [
    "all",
    [">", ["/", ["get", "nr_received"], partBaseline], 1],
    [">=", ["get", "availability"], 1]
  ];
};

const cl_temp_avail_monthly_comp_issue = partBaseline => {
  return [
    "all",
    [">=", ["/", ["get", "nr_received"], partBaseline], 0.3],
    ["<", ["/", ["get", "nr_received"], partBaseline], 0.8]
  ];
};
const cl_temp_avail_monthly_avail_issue = partBaseline => {
  return [
    "all",
    [">", ["/", ["get", "nr_received"], partBaseline], 0],
    ["<", ["/", ["get", "nr_received"], partBaseline], 0.3]
  ];
};
const cl_temp_avail_monthly_ok = partBaseline => {
  return [
    "all",
    [">=", ["/", ["get", "nr_received"], partBaseline], 0.8],
    ["<=", ["/", ["get", "nr_received"], partBaseline], 1],
    ["<=", ["get", "availability"], 3]
  ];
};

// Quality

const cl_temp_le_0p5 = ["<=", ["get", "rms"], 0.5];
const cl_temp_le_1 = ["<=", ["get", "rms"], 1];
const cl_temp_le_0p02 = ["<=", ["get", "rms"], 0.02];

const cl_temp_gt_0p02_le_0p05 = [
  "all",
  [">", ["get", "rms"], 0.02],
  ["<=", ["get", "rms"], 0.05]
];

const cl_temp_gt_0p05_le_0p1 = [
  "all",
  [">", ["get", "rms"], 0.05],
  ["<=", ["get", "rms"], 0.1]
];

const cl_temp_gt_0p1_le_0p15 = [
  "all",
  [">", ["get", "rms"], 0.1],
  ["<=", ["get", "rms"], 0.15]
];

const cl_temp_gt_0p15_le_0p3 = [
  "all",
  [">", ["get", "rms"], 0.15],
  ["<=", ["get", "rms"], 0.3]
];

const cl_temp_gt_0p5_le_1 = [
  "all",
  [">", ["get", "rms"], 0.5],
  ["<=", ["get", "rms"], 1]
];

const cl_temp_gt_1_le_3 = [
  "all",
  [">", ["get", "rms"], 1],
  ["<=", ["get", "rms"], 3]
];

const cl_temp_gt_3_le_5 = [
  "all",
  [">", ["get", "rms"], 3],
  ["<=", ["get", "rms"], 5]
];

const cl_temp_gt_5_le_8 = [
  "all",
  [">", ["get", "rms"], 5],
  ["<=", ["get", "rms"], 8]
];

const cl_temp_gt_5_le_10 = [
  "all",
  [">", ["get", "rms"], 5],
  ["<=", ["get", "rms"], 10]
];

const cl_temp_gt_8_le_15 = [
  "all",
  [">", ["get", "rms"], 8],
  ["<=", ["get", "rms"], 15]
];

const cl_temp_gt_0p3 = [">", ["get", "rms"], 0.3];
const cl_temp_gt_10 = [">", ["get", "rms"], 10];
const cl_temp_gt_15 = [">", ["get", "rms"], 15];

export const getStyleTempQuality = varid => {
  let style;
  if (varid === "2") {
    // temperature
    style = [
      "case",
      cl_temp_le_0p5,
      colors.map.temp.quality[0],
      cl_temp_gt_0p5_le_1,
      colors.map.temp.quality[1],
      cl_temp_gt_1_le_3,
      colors.map.temp.quality[2],
      cl_temp_gt_3_le_5,
      colors.map.temp.quality[3],
      cl_temp_gt_5_le_10,
      colors.map.temp.quality[4],
      cl_temp_gt_10,
      colors.map.temp.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else if (varid === "3" || varid === "4") {
    // wind
    style = [
      "case",
      cl_temp_le_1,
      colors.map.temp.quality[0],
      cl_temp_gt_1_le_3,
      colors.map.temp.quality[1],
      cl_temp_gt_3_le_5,
      colors.map.temp.quality[2],
      cl_temp_gt_5_le_8,
      colors.map.temp.quality[3],
      cl_temp_gt_8_le_15,
      colors.map.temp.quality[4],
      cl_temp_gt_15,
      colors.map.temp.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  } else {
    // relative humidity
    style = [
      "case",
      cl_temp_le_0p02,
      colors.map.temp.quality[0],
      cl_temp_gt_0p02_le_0p05,
      colors.map.temp.quality[1],
      cl_temp_gt_0p05_le_0p1,
      colors.map.temp.quality[2],
      cl_temp_gt_0p1_le_0p15,
      colors.map.temp.quality[3],
      cl_temp_gt_0p15_le_0p3,
      colors.map.temp.quality[4],
      cl_temp_gt_0p3,
      colors.map.temp.quality[5],
      // never used but needed
      colors.map.classNeeded
    ];
  }
  return style;
};
